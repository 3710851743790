/* eslint-disable react/react-in-jsx-scope */
import Heading from "@components/atoms/heading/Heading";
import Container from "@components/utils/container/Container";
import Stack from "@mui/material/Stack";
import styles from "./climateScenarioHome.module.css";
import { useState } from "react";
import CollapsibleBlock from "@components/molecules/collapsibleBlock/CollapsibleBlock";
import { Link } from "react-router-dom";
import ContentBlock from "@components/molecules/contentBlock/ContentBlock";
import { ArrowForward } from "@mui/icons-material";
import Button from "@components/atoms/button/Button";
import { ContainerMaxWidth } from "@components/utils/container/Container.types";

const ClimateScenarioHome = () => {
  const faqs = [
    {
      title: "Wat is de uitleg bij de scenario-namen?",
      content: (
        <>
          <ul>
            <li className={styles.headHd}>
              <strong>Hd-klimaatscenario:</strong> Hoge CO2-uitstoot (SSP5-8.5),
              verdrogend{" "}
            </li>
            <li className={styles.headHn}>
              <strong>Hn-klimaatscenario:</strong> Hoge CO2-uitstoot (SSP5-8.5),
              vernattend{" "}
            </li>
            <li className={styles.headLd}>
              <strong>Ld-klimaatscenario:</strong> Lage CO2-uitstoot (SSP1-2.6),
              verdrogend{" "}
            </li>
            <li className={styles.headLn}>
              <strong>Ln-klimaatscenario:</strong> Lage CO2-uitstoot (SSP1-2.6),
              vernattend{" "}
            </li>
          </ul>
          <p>
            Voor specifieke toepassingen zijn aanvullende berekeningen voor een
            matige CO2-uitstoot gemaakt:{" "}
          </p>
          <ul>
            <li className={styles.headMd}>
              Md-klimaatscenario: Matige CO2-uitstoot (SSP2-4.5), verdrogend
            </li>
            <li className={styles.headMn}>
              Mn-klimaatscenario: Matige CO2-uitstoot (SSP2-4.5), vernattend
            </li>
          </ul>
          <p>
            Zie ook de Veelgestelde vragen over de KNMI'23-klimaatscenario's op{" "}
            <Link to="https://www.knmi.nl/klimaatscenarios" target="_blank">
              www.knmi.nl/klimaatscenarios
            </Link>
            .
          </p>
        </>
      ),
    },
    {
      title: "Wat is er op dit dataportaal te vinden?",
      content: (
        <>
          <ul>
            <li>
              Kerncijfers: De kerncijfers zijn de getallen voor
              klimaatverandering gemiddeld over een periode van 30 jaar voor een
              tijdshorizon in de toekomst (2033, 2050, 2100, 2150).
            </li>
            <li>
              Modeluitvoer:  De modeluitvoer betreft 8 maal een set aan
              modeltijdreeksen voor klimaatverandering met dagwaarden voor een
              periode van 30 jaar voor het referentieklimaat en per scenario
              voor de tijdshorizonten in de toekomst (2033, 2050, 2100, 2150).
              De modeluitvoer is gegeven op een rooster dat Nederland bedekt met
              roostercellen die ongeveer 12 bij 12 kilometer groot zijn.
            </li>
            <li>
              Getransformeerde reeksen: De dagwaarden van een groot aantal
              stations verspreid over Nederland over de referentieperiode
              1991-2020 zijn statistisch zo getransformeerd dat ze zo goed
              mogelijk passen bij het klimaat in de toekomst volgens de
              KNMI-klimaatscenario's.
            </li>
            <p>
              Met tijdreeksen (modeluitvoer en getransformeerde reeksen) kan
              onderzoek gedaan worden naar de verandering van specifieke
              extremen, bijvoorbeeld het aantal dagen met een temperatuur boven
              de 25 graden. Ook kunnen ze als invoer gebruikt worden voor
              berekeningen van de impact van klimaatverandering.
            </p>
            <p>
              Een voordeel van de modeluitvoer boven de getransformeerde reeksen
              is dat deze ook een andere opeenvolging van weergebeurtenissen
              geven dan er in het verleden zijn opgetreden. Daarnaast zijn met
              klimaatmodellen meerdere reeksen te geven voor hetzelfde klimaat,
              ieder met andere weergebeurtenissen. Dit maakt het mogelijk om de
              kansen op extremen beter te bepalen.
            </p>
            <p>
              Een voordeel van getransformeerde reeksen boven modeluitvoer is
              dat de koppeling met opgetreden extremen in het verleden behouden
              blijft.
            </p>
          </ul>
        </>
      ),
    },
    {
      title: "Waar vind ik uitleg over het gebruik van de modeluitvoer? ",
      content: (
        <>
          <Link
            to="https://www.knmi.nl/kennis-en-datacentrum/achtergrond/modeluitvoer-knmi-23-klimaatscenario-s"
            target="_blank"
          >
            Modeluitvoer KNMI’23-klimaatscenario’s
          </Link>
        </>
      ),
    },
    {
      title: "Waar vind ik uitleg over de getransformeerde tijdreeksen?",
      content: (
        <Link
          to="https://cdn.knmi.nl/system/ckeditor/attachment_files/data/000/000/298/original/TSTransManual_KNMI23_1.0.pdf"
          key="Handleiding voor gebruik van modeluitvoer en getransformeerde reeksen (PDF, EN)"
          target="_blank"
        >
          Handleiding voor gebruik van modeluitvoer en getransformeerde reeksen
          (PDF, EN)
        </Link>
      ),
    },
    {
      title: "Naar meer Veelgestelde vragen door gebruikers KNMI’23",
      content: (
        <>
          <p>
            <Link
              to="https://www.knmi.nl/kennis-en-datacentrum/achtergrond/knmi-23-klimaatscenario-s-voor-gebruikers/#3"
              target="_blank"
            >
              Hier (KNMI - KNMI'23-klimaatscenario's voor gebruikers)
            </Link>{" "}
            vind je meer veelgestelde vragen, zoals:{" "}
          </p>
          <ol>
            <li>
              Wat is het verschil tussen de KNMI’14- en
              KNMI’23-klimaatscenario’s?{" "}
            </li>
            <li>
              Welke informatie vind ik in de gebruikershandleiding voor de
              scenario’s?
            </li>
            <li>Welke data en tijdreeksen zijn er beschikbaar?</li>
            <li>
              Waar kan ik informatie vinden over de praktische toepassing van de
              klimaatscenario’s?
            </li>
            <li>Waar vind ik internationale klimaatportalen en datasets?</li>
          </ol>
        </>
      ),
    },
  ];
  const [isOpen, setIsOpen] = useState(
    Array.from({ length: faqs.length }, () => false)
  );

  const updateIsOpen = (i: number) => {
    setIsOpen((prevValue) =>
      prevValue.map((item, index) => (index === i ? !item : item))
    );
  };

  return (
    <Container maxWidth={ContainerMaxWidth.MAX}>
      <Stack direction="column" gap={4}>
        <ContentBlock
          title="KNMI’23-klimaatscenario’s Dataportaal"
          image="/knmi_klimaatscenario_info.jpg"
        >
          <p>
            Het KNMI maakt klimaatscenario’s voor het toekomstig klimaat in
            Nederland. Met behulp van de scenario’s kunnen we ons land aanpassen
            aan het weer van de toekomst.
          </p>
          <p>
            De hoge <strong>(H)</strong> en lage scenario’s <strong>(L)</strong>
            , geven min of meer de grenzen aan waarbinnen het Nederlandse
            klimaat zich zal ontwikkelen. Er worden aan de scenario’s geen
            waarschijnlijkheden toegekend. Ieder scenario beschrijft een
            ontwikkeling die mogelijk gaat plaatsvinden. Dit dataportaal bevat
            alle beschikbare data van de KNMI’23-klimaatscenario’s: de
            kerncijfers, de uitvoer van het klimaatmodel waarop de kerncijfers
            gebaseerd zijn en de getransformeerde tijdreeksen.
          </p>
          <a href="/kerncijfers">
            <Button
              as="button"
              label="Naar de kerncijfers"
              icon={<ArrowForward />}
              className={styles.button}
              color="dark"
            />
          </a>
        </ContentBlock>
        <Heading level={1} centered className={styles.heading}>
          Veelgestelde vragen KNMI'23-klimaatscenario's data portal
        </Heading>
        {faqs.map((faq, i) => (
          <div className={styles.collapsible} key={i}>
            <CollapsibleBlock
              title={faq.title}
              isOpen={isOpen[i]}
              setOpen={() => updateIsOpen(i)}
            >
              <p>{faq.content}</p>
            </CollapsibleBlock>
          </div>
        ))}
      </Stack>
    </Container>
  );
};

export default ClimateScenarioHome;
