import type { Theme } from "@mui/material"

const Components: Theme["components"] = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        ...ownerState,
        background: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.main,
        borderRadius: 4,
        color: theme.palette.primary.main,
        padding: '1rem',
        textTransform: 'none',
        transition: '.1s ease',
        transitionProperty: 'background, color',

        "&:hover": {
          background: theme.palette.primary.light,
          color: theme.palette.primary.main
        },
        "&.Mui-selected, &.Mui-selected:hover": {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }
      }),
      standard: ({ ownerState, theme }) => ({
        ...ownerState,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }),
    }
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      grouped: ({ theme }) => ({
        "&:not(:first-of-type)": {
          borderLeftColor: theme.palette.primary.main
        }
      })
    }
  },
}

export default Components