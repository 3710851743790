import { PropsWithChildren, ReactElement, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import {
  default as MaterialTooltip,
  TooltipProps as MaterialTooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

interface TooltipProps {
  tooltip: string | ReactNode;
}

const StyledTooltip = styled(
  ({ className, children, ...props }: MaterialTooltipProps) => (
    <MaterialTooltip
      {...props}
      classes={{ popper: className }}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      {children}
    </MaterialTooltip>
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "black",
    fontSize: ".875rem",
    lineHeight: "1rem",
    padding: " .5rem 1rem",
    maxWidth: 500,
  },
});

const Tooltip = ({ children, tooltip }: PropsWithChildren<TooltipProps>) => {
  return (
    <StyledTooltip title={tooltip}>{children as ReactElement}</StyledTooltip>
  );
};

export default Tooltip;
