const StationTooltip = (
  <>
    <p>
      Landelijk gemiddelden zijn bepaald op basis van de volgende meetstations:
    </p>
    <ul>
      <li>
        Temperatuur, zonnestraling, verdamping, vochtigheid, wind: gemiddelde
        van vijf stations: De Bilt (260_H), De Kooy (235_H), Eelde (280_H),
        Vlissingen (310_H), Maastricht (380_H).
      </li>
      <li>
        Neerslag: gemiddelde van 13 neerslagstations (P13): De Bilt (550_N), De
        Kooy (25_N), Groningen (139_N), Heerde (328_N), Hoofddorp (438_N), Hoorn
        (222_N), Kerkwerve (737_N), Oudenbosch (828_N), Roermond (961_N), Ter
        Apel (144_N), West-Terschelling (11_N), Westdorpe (770_N) en Winterswijk
        (666_N).
      </li>
      <li>
        Droogte: P13 neerslagstations + verdamping op de meest nabije
        KNMI-stations.
      </li>
    </ul>
    <p>De lokale tabellen zijn gebaseerd op de volgende meetstations:</p>
    <ul>
      <li>De Bilt: De Bilt (260_H) en neerslagstation De Bilt (550_N)</li>
      <li>Groningen: Eelde (280_H) en neerslagstation Groningen (139_N)</li>
      <li>
        Vlissingen: Vlissingen (310_H) en neerslagstation Kerkwerve (737_N)
      </li>
      <li>
        Maastricht: Maastricht (380_H) en neerslagstation Valkenburg (963_N)
      </li>
      <li>
        Den Helder: De Kooy (235_H) en neerslagstation De Kooy (25_N) (t/m 1971:
        Den Helder (9_N))
      </li>
    </ul>
  </>
);

export default StationTooltip;
