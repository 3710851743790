import ScenarioTooltip from './tooltips/Scenario'
import SeasonTooltip from './tooltips/Season'
import StationTooltip from './tooltips/Station'
import YearsTooltip from './tooltips/Years'
import NaturalVariationsTooltip from './tooltips/NaturalVariations'

const getClimateScenarioFilterTooltip = (key: string) => {
  switch (key) {
    case "years":
      return YearsTooltip
    case "scenarios":
      return ScenarioTooltip
    case "seasons":
      return SeasonTooltip
    case "station":
      return StationTooltip
    case "natural_variations":
      return NaturalVariationsTooltip

    default:
      return undefined
  }
}

export default getClimateScenarioFilterTooltip