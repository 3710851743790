const YearsTooltip = (
  <>
    <p>
      Vink aan voor welke tijdshorizon(ten) in de toekomst je de
      klimaatverandering wilt zien. Een tijdshorizon is een periode van 30 jaar;
      '2050' is het gemiddelde over de periode 2036-2065
    </p>
    <p>
      Rond 2033 gaan we naar verwachting wereldgemiddeld over 1,5°C
      temperatuurstijging heen ten opzichte van pre-industrieel.
    </p>
  </>
);

export default YearsTooltip;
