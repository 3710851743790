import { PropsWithChildren } from "react";
import styles from "./label.module.css";

export enum labelColors {
    Ld = "labelLd",
    Ln = "labelLn",
    Md = "labelMd",
    Mn = "labelMn",
    Hd = "labelHd",
    Hn = "labelHn",

}
interface LabelProps {
    color: labelColors
    title: string

}
const Label = ({
    color,
    title,
    children
}: PropsWithChildren<LabelProps>) => (
  <>
    <span className={`${styles.label} ${styles[color]}`}>
    {title}
    </span>{" "}
    {children}
  </>
);

export default Label;
