import { PropsWithChildren } from "react";
import classNames from "classnames";

import { BlockProps } from "./Block.types";
import styles from "./block.module.css";

const Block = ({
  className,
  hasImg,
  children,
}: PropsWithChildren<BlockProps>) => {
  return (
    <div
      className={classNames(
        styles.component,
        hasImg ? styles.hasImg : "",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Block;
