import Label, { labelColors } from "@components/atoms/label/Label";
import styles from "./tooltip.module.css";

const ScenarioTooltip = (
  <>
    <p>
      Omdat het onzeker is hoeveel er wereldwijd wordt gedaan om de uitstoot te
      verminderen, zijn er scenario's voor hoge CO<sub>2</sub>-uitstoot{" "}
      <strong>(H)</strong> en lage <strong>(L)</strong>. Die zijn allebei
      doorgerekend voor een verdrogende <strong>(d)</strong> en een vernattende{" "}
      <strong>(n)</strong> situatie.
    </p>
    <p>
      Voor de meeste toepassingen is het zinvol om met deze vier scenario's te
      rekenen:
    </p>
    <ul className={styles.listNone}>
      <li>
        <Label color={labelColors.Hd} title="Hd-klimaatscenario">
          Hoge CO<sub>2</sub>-uitstoot (SSP5-8.5), verdrogend
        </Label>
      </li>
      <li>
        <Label color={labelColors.Hn} title="Hn-klimaatscenario">
          Hoge CO<sub>2</sub>-uitstoot (SSP5-8.5), vernattend
        </Label>
      </li>
      <li>
        <Label color={labelColors.Ld} title="Ld-klimaatscenario">
          Lage CO<sub>2</sub>-uitstoot (SSP1-2.6), verdrogend
        </Label>
      </li>
      <li>
        <Label color={labelColors.Ln} title="Ln-klimaatscenario">
          Lage CO<sub>2</sub>-uitstoot (SSP1-2.6), vernattend
        </Label>
      </li>
    </ul>
    <p>
      Voor specifieke toepassingen zijn aanvullende berekeningen voor een matige
      CO<sub>2</sub>-uitstoot gemaakt:
    </p>
    <ul className={styles.listNone}>
      <li>
        <Label color={labelColors.Md} title="Md-klimaatscenario">
          Matige CO<sub>2</sub>-uitstoot (SSP2-4.5), verdrogend
        </Label>
      </li>
      <li>
        <Label color={labelColors.Mn} title="Mn-klimaatscenario">
          Matige CO<sub>2</sub>-uitstoot (SSP2-4.5), vernattend
        </Label>
      </li>
    </ul>
  </>
);

export default ScenarioTooltip;
