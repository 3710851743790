import { Ref, createElement, forwardRef } from "react";
import classNames from "classnames";
import { useButton } from "@mui/base/useButton";

import { ButtonProps } from "./Button.types";
import styles from "./button.module.css";

const Button = forwardRef(
  (
    { label, as, icon, color, shape, className, ...extraProps }: ButtonProps,
    ref: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement> | Ref<HTMLDivElement>
  ) => {
    const buttonProps = useButton({
      ...extraProps,
      rootRef: ref,
    });

    const element = createElement(
      as,
      {
        ...buttonProps.getRootProps(),
        className: classNames(styles.component, className, {
          [styles[`${color}Colored`]]: color,
          [styles[`${shape}Shaped`]]: shape,
        }),
      },
      <>
        {label && <span className={styles.label}>{label}</span>}
        {icon && <span className={styles.icon}>{icon}</span>}
      </>
    );

    return element;
  }
);

export default Button;
