import { useCallback, useState } from "react";
import FormControl from "@mui/material/FormControl";
import parser from "query-string-parser";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Button } from "@mui/material";

export enum DownloadFormats {
  csv = "CSV",
  zip = "ZIP",
}

interface DownloadButtonProps {
  baseUrl: string;
  format: DownloadFormats.csv | DownloadFormats.zip;
  filters: { [key: string]: string[] | string };
  filterKey: string;
  styleOverride?: any;
  disabled?: boolean;
}

const DownloadButton = ({
  baseUrl,
  format,
  filters,
  filterKey,
  styleOverride,
  disabled = false,
}: DownloadButtonProps) => {
  const [loading, setLoading] = useState(false);

  const downloadData = useCallback(
    async (e: any) => {
      e.preventDefault();
      const paramsObject = {
        [filterKey]: {
          ...filters,
        },
      };

      const searchParams = parser.toQuery(paramsObject);
      const url = `${baseUrl}.${format.toLocaleLowerCase()}?${searchParams}`;

      try {
        setLoading(true);

        const res = await fetch(url, {
          method: "GET",
        });

        // Check if the response contains the Content-Disposition header.
        const contentDisposition = res.headers.get("Content-Disposition");

        // Extract the filename from the Content-Disposition header.
        let filename = "downloaded_file"; // Default filename if not provided by the server.
        if (contentDisposition) {
          const filenameMatch = /filename="(.*)"/.exec(contentDisposition);
          if (filenameMatch) {
            filename = filenameMatch[1];
          }
        }

        const blob = await res.blob();
        const file = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download.
        const a = document.createElement("a");
        a.href = file;
        a.download = filename;
        a.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [baseUrl, format, filters, filterKey]
  );

  return (
    <FormControl sx={styleOverride} disabled={disabled}>
      <Button
        variant="contained"
        onClick={(e) => downloadData(e)}
        disabled={disabled || loading}
        sx={{
          height: "56px",
          textTransform: "none",
          justifyContent: "flex-start",
        }}
      >
        Download
        {loading && (
          <HourglassBottomIcon
            sx={{
              width: 16,
              height: 16,
              animation: "spin 2s linear infinite",
              keyframes: {
                "@keyframes spin": {
                  from: { transform: "rotate(0deg)" },
                  to: { transform: "rotate(360deg)" },
                },
              },
            }}
          />
        )}
      </Button>
    </FormControl>
  );
};

export default DownloadButton;
