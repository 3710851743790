import { MapContainer } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import { useEffect, useMemo, useRef, useState } from "react";
import BaseLayers from "./BaseLayers";
import Paths from "constants/Paths";

import "leaflet/dist/leaflet.css";
import "./map.css";
import { useQuery } from "react-query";

interface MapProps {
  selectStation: (station: string) => void;
  closeMap: () => void;
}

const Map = ({ selectStation, closeMap }: MapProps) => {
  const [mapReady, setMapReady] = useState(false);
  const { data, isLoading, isError } = useQuery(["mapStations"], async () => {
    const response = await fetch(Paths.CLIMATE_MAP_STATIONS, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return await response.json();
  });

  const mapRef = useRef<L.Map>(null);
  const markers = L.markerClusterGroup({
    showCoverageOnHover: false,
  });

  useEffect(() => {
    if (mapRef.current && data?.stations) {
      const markerList = data.stations.map((marker, index) => {
        const customMarker = L.divIcon({
          html: `<div class="custom-marker"><p>${marker.province} - ${marker.city}</p></div>`,
        });

        const leafletMarker = L.marker([marker.lat, marker.lon], {
          icon: customMarker,
        });

        leafletMarker.on("click", () => {
          selectStation(marker.key);
          closeMap();
        });

        return leafletMarker;
      });

      markers.addLayers(markerList);
      mapRef.current.addLayer(markers);
    }
  }, [mapRef.current, data?.stations, selectStation, closeMap, mapReady]);

  const map_box_style_id = import.meta.env.VITE_MAP_STYLE_ID;
  const base_layer_coords = [
    {
      lat: 53.57885,
      lng: 3.573652,
    },
    {
      lat: 50.742863,
      lng: 6.879572,
    },
  ];

  const baseLayerBounds = () => {
    return new L.LatLngBounds(
      base_layer_coords.map(({ lat, lng }) => {
        return [lat, lng] as L.LatLngTuple;
      })
    );
  };

  const mapTileUser = import.meta.env.VITE_MAP_USERNAME;
  const mapTileStyles = map_box_style_id || import.meta.env.VITE_MAP_STYLE_ID;
  const mapTileAccessToken = import.meta.env.VITE_MAP_ACCESS_TOKEN;
  const mapBaseLayers: L.Control.LayersObject = useMemo(() => {
    const baseLayers: L.Control.LayersObject = {};
    if (!mapTileStyles) {
      baseLayers["OpenStreetMap"] = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          id: "OpenStreetMaps",
        }
      );
    }

    // split map styles by comma and add to as mapbox layer
    if (mapTileUser && mapTileStyles && mapTileAccessToken) {
      mapTileStyles.split(",").map((styleId: string) => {
        const mapTileURL = `https://api.mapbox.com/styles/v1/${mapTileUser}/${styleId}/tiles/{z}/{x}/{y}?access_token=${mapTileAccessToken}`;
        baseLayers[`Mapbox ${styleId}`] = L.tileLayer(mapTileURL, {
          id: styleId,
        });
      });
    }

    return baseLayers;
  }, [mapTileUser, mapTileStyles, mapTileAccessToken]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;

  return (
    <MapContainer
      ref={mapRef}
      bounds={baseLayerBounds()}
      style={{ width: "100%", height: "90%" }}
      zoomControl={false}
      attributionControl={false}
      whenReady={() => {
        setMapReady(true);
      }}
    >
      <BaseLayers baseLayers={mapBaseLayers} />
    </MapContainer>
  );
};

export default Map;
