/* eslint-disable react/react-in-jsx-scope */
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Controller } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Stack from "@mui/material/Stack";

import Paths from "constants/Paths";
import keyToPropertyName from "helpers/KeyToPropertyName";
import useClimateScenarioFilters from "hooks/useClimateScenarioFilters";
import Table from "@components/atoms/table/Table";
import Heading from "@components/atoms/heading/Heading";
import DownloadDataDropdown, {
  DownloadFormats,
} from "@components/molecules/downloadDataDropdown/DownloadDataDropdown";
import ClimateScenarioFilters from "@components/organisms/climateScenarioFilters/ClimateScenarioFilters";
import Container from "@components/utils/container/Container";
import Block from "@components/atoms/block/Block";
import { ContainerMaxWidth } from "@components/utils/container/Container.types";

import parser from "query-string-parser";

import {
  ClimateTableData,
  ClimateTableDataItem,
  ClimateTableDataVariables,
} from "types/ClimateTableData";
import CollapsibleBlock from "@components/molecules/collapsibleBlock/CollapsibleBlock";
import ContentBlock from "@components/molecules/contentBlock/ContentBlock";

import styles from "./climateScenarioTable.module.css";
import useDebouncedQuery from "hooks/useDebounce";
import ClimateScenarioToggleFilters from "@components/organisms/climateScenarioToggleFilters/climateScenarioToggleFilters";
import ScenarioTooltip from "../climateScenarioFilters/tooltips/Scenario";
import Label, { labelColors } from "@components/atoms/label/Label";

import { Margin, usePDF } from "react-to-pdf";
import ClimateScenarioTablePdf from "./ClimateScenarioTablePdf";

const ClimateScenariosTable = () => {
  const columnHelper = createColumnHelper<ClimateTableDataItem>();

  const [explanationOpen, setExplanationOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState<
    ClimateTableDataVariables | undefined
  >(undefined);
  const [filterData, setFilterData] = useState<
    { [key: string]: string[] | string } | undefined
  >(undefined);

  const { defaultValues, baseFilters, toggleFilters, filterForm } =
    useClimateScenarioFilters({
      defaultFilters,
    });
  // Update filter state to update filterQuery
  useEffect(() => {
    const sub = filterForm.watch((values) => {
      if (!defaultFilters) return;
      setFilterData(values);
    }, defaultValues);
    return () => sub.unsubscribe();
  }, [filterForm.watch, defaultFilters]);

  // Filter query is used to trigger a new api call if filters are changed
  // TODO: react-query does not need a single string to track state - can also just spread all filters as state
  const filterQuery = useMemo(() => {
    const values = Object.values(filterData ?? {});
    if (values.length === 0) return "";

    return values
      .map((value) => (Array.isArray(value) ? value.join(",") : value))
      .join("-");
  }, [filterData]);

  const debouncedFilterQuery = useDebouncedQuery(filterQuery, 0);

  // Query to fetch data
  const { data, isLoading, isError, refetch } = useQuery<ClimateTableData>(
    ["climateScenarioTableData", debouncedFilterQuery],
    async () => {
      let response;
      if (!filterData) {
        // No filters changed - fetch default
        response = await fetch(Paths.CLIMATE_TABLE_DATA, {
          method: "GET",
        });
      } else {
        // Filters are changed - fetch with filters
        const data: {
          [key: string]:
            | string
            | {
                [key: string]: boolean | string;
              };
        } = {};
        // Map filter data to usable data for api call
        Object.entries(filterData).forEach(([key, values]) => {
          if (Array.isArray(values)) {
            data[key] = {};
            values.forEach((value) => (data[key][value] = true));
          } else {
            data[key] = values;
          }
        });

        const paramsObject = {
          table_variables: {
            ...filterData,
          },
        };

        const searchParams = parser.toQuery(paramsObject);

        response = await fetch(`${Paths.CLIMATE_TABLE_DATA}?${searchParams}`);
      }

      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      return await response.json();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { toPDF, targetRef } = usePDF({
    filename: `knmi-klimaatscenarios-kerncijfers_${(
      defaultFilters?.station?.options[
        (filterData?.station as string) || (defaultValues?.station as string)
      ] || "Landelijk gemiddelde"
    )
      .toLowerCase()
      .replace(/ /g, "-")}.pdf`,
    page: {
      margin: Margin.MEDIUM,
      orientation: "landscape",
    },
  });

  // Update the filter defaults once when the first call is done
  useEffect(() => {
    setDefaultFilters((prevDefaults) => {
      if (prevDefaults === undefined && data !== undefined) {
        return data.table_variables;
      }

      return prevDefaults;
    });
  }, [data, setDefaultFilters]);

  // Define the table data and columns based on the data from the api
  const [tableData, tableColumns] = useMemo(() => {
    if (!data?.table_view.rows) return [undefined, undefined];

    const columnsData = data.table_view.columns;
    const rowsData = data.table_view.rows;
    const trendsData = data.table_view.trend_rows;

    const columns = [
      // default columns for season, variable and indicator columns
      columnHelper.accessor((row) => row.season, {
        id: "season",
        header: columnsData.legend[0],
        cell: (info) => info.getValue<ReactNode>(),
        size: 100,
        meta: {
          isSticky: true,
          forceRowSpan: true,
        },
      }),
      columnHelper.accessor((row) => row.variable, {
        id: "variable",
        header: columnsData.legend[1],
        cell: (info) => info.getValue<ReactNode>(),
        size: 160,
        meta: {
          isSticky: true,
          forceRowSpan: true,
        },
      }),
      columnHelper.accessor((row) => row.indicator, {
        id: "indicator",
        header: columnsData.legend[2],
        cell: (info) => info.getValue<ReactNode>(),
        minSize: 250,
        meta: {
          cellFill: true,
        },
      }),
      // Columns for dynamic columns in data_columns
      ...columnsData.data_columns.map((col, colIndex) => {
        return columnHelper.accessor(keyToPropertyName(col + colIndex), {
          id: keyToPropertyName(col + colIndex),
          header: col,
          cell: (info) => info.getValue<ReactNode>(),
          meta: {
            colName: col,
          },
        });
      }),
    ];

    const getCellValue = (
      value: string,
      footnote?: number
    ): string | ReactNode => {
      if (!footnote) return value;

      return (
        <>
          <span>{value}</span>
          <sup>{footnote}</sup>
        </>
      );
    };

    // Map rows to use in table
    const rows = rowsData.map((row) => {
      const extraData = {};
      const footnoteRef = row.footnote_refs;
      row.data_columns.forEach((extraCol, index) => {
        const footnoteValue = extraCol.footnote_refs;
        extraData[keyToPropertyName(columnsData.data_columns[index] + index)] = getCellValue(extraCol.value, footnoteValue);
      });

      return {
        season: getCellValue(row.season, footnoteRef?.season),
        variable: getCellValue(row.variable, footnoteRef?.variable),
        indicator: getCellValue(row.indicator, footnoteRef?.indicator),
        ...extraData,
      };
    });
    const extraRows = trendsData?.map((row) => {
      const extraData = {};
      row.data_columns.map((column, index) => {
        const footnoteValue = column.footnote_refs;
        extraData[keyToPropertyName(columnsData.data_columns[index] + index)] = getCellValue(column.value, footnoteValue);
      });

      return {
        season: row.title,
        variable: undefined,
        indicator: undefined,
        ...extraData,
      };
    });

    if (extraRows) {
      return [[...extraRows, ...rows], columns];
    }
    return [rows, columns];
  }, [data]);

  return (
    <Container maxWidth={ContainerMaxWidth.MAX}>
      <Stack direction="column" gap={4}>
        <CollapsibleBlock
          title="Uitleg Kerncijfers"
          isOpen={explanationOpen}
          setOpen={setExplanationOpen}
          img="/kerncijfers-hero.png"
        >
          <div>
            <p>
              De kerncijfers zijn de getallen voor klimaatverandering gemiddeld
              over een periode van 30 jaar voor een tijdshorizon in de toekomst.
            </p>
            <p>
              Stel je eigen tabel samen door in het menu Filters de voor jou
              relevante variabelen, scenario’s en tijdshorizonten aan te vinken.
              In de tabel staan de getallen voor klimaatverandering: het
              verschil tussen het klimaat in de toekomst ten opzichte van ‘het
              klimaat van nu’ (1991-2020). Je kunt ook kiezen voor de waarde in
              de toekomst zelf.
            </p>
            <p>
              De getallen voor klimaatverandering zijn voor temperatuur afgerond
              op 0,1°C, voor de andere variabelen onder de tien op 0,1 (mm,
              dagen, %, m/s, W/m<sup>2</sup>) en daarboven op hele getallen.
              Ranges zijn afgerond op hele getallen.
            </p>
            <Heading level={2}>Scenario</Heading>
            <p>
              Omdat het onzeker is hoeveel er wereldwijd wordt gedaan om de
              uitstoot te verminderen, zijn er scenario's voor hoge CO
              <sub>2</sub>-uitstoot <strong>(H)</strong> en lage{" "}
              <strong>(L)</strong>. Die zijn allebei doorgerekend voor een
              verdrogende <strong>(d)</strong> en een vernattende{" "}
              <strong>(n)</strong> situatie.
            </p>
            <p>
              Voor de meeste toepassingen is het zinvol om met deze vier
              scenario's te rekenen:
            </p>
            <ul className={styles.listNone}>
              <li>
                <Label color={labelColors.Hd} title="Hd-klimaatscenario">
                  Hoge CO<sub>2</sub>-uitstoot (SSP5-8.5), verdrogend
                </Label>
              </li>
              <li>
                <Label color={labelColors.Hn} title="Hn-klimaatscenario">
                  Hoge CO<sub>2</sub>-uitstoot (SSP5-8.5), vernattend
                </Label>
              </li>
              <li>
                <Label color={labelColors.Ld} title="Ld-klimaatscenario">
                  Lage CO<sub>2</sub>-uitstoot (SSP1-2.6), verdrogend
                </Label>
              </li>
              <li>
                <Label color={labelColors.Ln} title="Ln-klimaatscenario">
                  Lage CO<sub>2</sub>-uitstoot (SSP1-2.6), vernattend
                </Label>
              </li>
            </ul>
            <p>
              Voor specifieke toepassingen zijn aanvullende berekeningen voor
              een matige CO<sub>2</sub>-uitstoot gemaakt:
            </p>
            <ul className={styles.listNone}>
              <li>
                <Label color={labelColors.Md} title="Md-klimaatscenario">
                  Matige CO<sub>2</sub>-uitstoot (SSP2-4.5), verdrogend
                </Label>
              </li>
              <li>
                <Label color={labelColors.Mn} title="Mn-klimaatscenario">
                  Matige CO<sub>2</sub>-uitstoot (SSP2-4.5), vernattend
                </Label>
              </li>
            </ul>
            <Heading level={2}>Tijdhorizon</Heading>
            <p>
              De scenario’s zijn voor de volgende tijdshorizonten in de toekomst
              berekend: 2033, 2050, 2100, 2150. Een tijdshorizon is een periode
              van 30 jaar; ‘2050’ is het gemiddelde over de periode 2036-2065.
              Rond 2033 gaan we naar verwachting wereldgemiddeld over 1,5°C
              temperatuurstijging heen ten opzichte van pre-industrieel. Het
              2033 scenario is alleen voor het lage uitstootscenario beschikbaar
              (L). De Ld en Ln scenario’s zijn alleen voor 2100 gegeven, maar
              zijn gelijk voor 2050 en 2150.
            </p>
          </div>
        </CollapsibleBlock>

        {defaultFilters !== undefined && (
          <ClimateScenarioFilters
            defaultValues={defaultValues}
            filters={baseFilters}
            form={filterForm}
            isLoading={isLoading}
            refetch={refetch}
            withRefresh
          />
        )}
      </Stack>

      <Heading level={1} centered className={styles.heading}>
        Kerncijfers KNMI'23
      </Heading>

      <Block className={styles.tableBlock}>
        <div className={styles.tableHeadBlock}>
          <Stack
            direction="row"
            gap={2}
            flexWrap="wrap"
            className={styles.tableHeadLeft}
          >
            <ClimateScenarioToggleFilters
              filters={toggleFilters}
              form={filterForm}
              forceDisable={
                filterData?.natural_variations?.length > 0 && "trend"
              }
              forceEnable={
                filterData?.natural_variations?.length > 0 && "waarde"
              }
            />
          </Stack>
          <h3 className={styles.tableHeadCenter}>
            {data?.table_variables?.station.options[
              data?.table_variables?.station?.checked as string
            ] || "Landelijk gemiddelde"}
          </h3>
          <div className={styles.tableHeadRight}>
            <DownloadDataDropdown
              baseUrl={Paths.CLIMATE_TABLE_DATA}
              filename="knmi_klimaatscenario_tabel.csv"
              types={[DownloadFormats.csv, DownloadFormats.pdf]}
              filters={filterData}
              filterKey="table_variables"
              pdfDownload={() => toPDF()}
            />
          </div>
        </div>
        {isLoading && <p className={styles.label}>Loading...</p>}
        {isError && !isLoading && (
          <p className={styles.label}>
            Something went wrong retrieving the data. Try using different
            filters
          </p>
        )}
        {!isError && !isLoading && tableData.length === 0 && (
          <p className={styles.label}>
            No data found. Try using different filters
          </p>
        )}

        {!isError && !isLoading && tableData.length > 0 && (
          <div className={styles.tableContainer}>
            <Table<ClimateTableDataItem>
              data={tableData}
              columns={tableColumns}
              className={styles.table}
            />
          </div>
        )}

        <div className={styles.footNote}>
          <div>Extra uitleg</div>
          <ol>
            <li>
              De getallen voor zeespiegel gelden ten opzichte van het gemiddelde
              over 1995-2014.
            </li>
            <li>
              De relatieve vochtigheid is in procentpunten (voorbeeld: 80% in de
              referentie, met 1% erbij wordt 81%).
            </li>
            <li>
              De hoeveelheid neerslag in de referentieperiode is de hoeveelheid
              die gemiddeld eens per jaar op een locatie voorkomt, zoals
              beschreven in STOWA 2019-19, Neerslagstatistiek en -reeksen voor
              het waterbeheer 2019, update volgt in 2024. Afgerond op hele
              getallen.
            </li>
            <li>
              Gebaseerd op een andere methodiek en modellen en met een
              onzekerheidsband vanwege de bandbreedte in kleinschalige processen
              rond buien (zie hoofdstuk Zomerbuien, hagel en onweer in het{" "}
              <a
                href="https://www.knmi.nl/kennis-en-datacentrum/achtergrond/knmi-23-klimaatscenario-s"
                target="_blank"
              >
                gebruikersrapport
              </a>
              ).
            </li>
          </ol>
        </div>

        <ClimateScenarioTablePdf
          targetRef={targetRef}
          title={
            data?.table_variables?.station.options[
              data?.table_variables?.station?.checked as string
            ] || "Landelijk gemiddelde"
          }
        >
          {!isError && !isLoading && tableData.length > 0 && (
            <div className={styles.tableContainer}>
              <Table<ClimateTableDataItem>
                data={tableData}
                columns={tableColumns}
                className={styles.table}
              />
            </div>
          )}
        </ClimateScenarioTablePdf>
      </Block>
    </Container>
  );
};

export default ClimateScenariosTable;
