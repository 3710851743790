const labels = {
  climate_variables: "Klimaatvariabele",
  historical_periods: "Periode",
  scenarios: "Scenario",
  years: "Tijdshorizon",
  seasons: "Seizoen",
  station: "Selecteer een station",
  data_types: "Data type",
  natural_variations: "Natuurlijke variaties"
};

const getClimateScenarioFilterLabel = (key: string) => {
  const value = labels[key];
  if (value) {
    return value;
  }
  return key;
};

export default getClimateScenarioFilterLabel;
