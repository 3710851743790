import { useCallback, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import type { SelectChangeEvent } from "@mui/material/Select";
import parser from "query-string-parser";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export enum DownloadFormats {
  csv = "CSV",
  zip = "ZIP",
  pdf = "PDF"
}

interface DownloadDataDropdownProps {
  baseUrl: string;
  filename: string;
  types: DownloadFormats[];
  filters: { [key: string]: string[] | string };
  filterKey: string;
  styleOverride?: any;
  disabled?: boolean;
  pdfDownload?: () => void;
}

const DownloadDataDropdown = ({
  baseUrl,
  filename,
  types = [DownloadFormats.csv, DownloadFormats.zip],
  filters,
  filterKey,
  styleOverride,
  disabled = false,
  pdfDownload
}: DownloadDataDropdownProps) => {
  const selectRef = useRef<HTMLSelectElement>();
  const [format, setFormat] = useState("");
  const [loading, setLoading] = useState(false);

  const downloadData = useCallback(
    async (format: DownloadFormats) => {
      const paramsObject = {
        [filterKey]: {
          ...filters,
        },
      };

      const searchParams = parser.toQuery(paramsObject);
      const url = `${baseUrl}.${format}?${searchParams}`;

      try {
        setLoading(true);

        const res = await fetch(url, {
          method: "GET",
        });

        // Check if the response contains the Content-Disposition header.
        const contentDisposition = res.headers.get("Content-Disposition");

        // Extract the filename from the Content-Disposition header.
        let filename = "downloaded_file"; // Default filename if not provided by the server.
        if (contentDisposition) {
          const filenameMatch = /filename="(.*)"/.exec(contentDisposition);
          if (filenameMatch) {
            filename = filenameMatch[1];
          }
        }

        const blob = await res.blob();
        const file = window.URL.createObjectURL(blob);

        // Create an anchor element to trigger the download.
        const a = document.createElement("a");
        a.href = file;
        a.download = filename;
        a.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [baseUrl, filename, format, filters, filterKey]
  );

  const onFormatSelected = useCallback(
    (e: SelectChangeEvent) => {
      const value = e.target.value;
      if (value !== "") {
        setFormat(value);
        if(value === 'pdf') {
          pdfDownload()
        }

        else {
          downloadData(value as DownloadFormats);
        }
        setTimeout(() => {
          setFormat("");
          if (selectRef.current !== undefined) {
            selectRef.current.blur();
          }
        }, 400);
      }

      
    },
    [filters]
  );

  const downloadFormats = types.filter((type) =>
    Object.values(DownloadFormats).includes(type)
  );

  return (
    <FormControl sx={styleOverride} disabled={disabled}>
      <InputLabel id="download-label">Download data</InputLabel>
      <Select
        ref={selectRef}
        sx={{ minWidth: 210 }}
        id="download"
        labelId="download-label"
        label="Download data"
        value={format}
        onChange={onFormatSelected}
      >
        {Object.entries(downloadFormats).map(([key, label]) => (
          <MenuItem value={label.toLowerCase()} key={key}>
            {label}{" "}
            {loading && (
              <HourglassBottomIcon
                sx={{
                  width: 16,
                  height: 16,
                  animation: "spin 2s linear infinite",
                  keyframes: {
                    "@keyframes spin": {
                      from: { transform: "rotate(0deg)" },
                      to: { transform: "rotate(360deg)" },
                    },
                  },
                }}
              />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DownloadDataDropdown;
