import { UseFormReturn } from "react-hook-form";
import type { QueryObserverBaseResult } from "react-query";
import {
  ClimateTableDataVariables,
  ClimateTableDataVariablesItem,
} from "types/ClimateTableData";

export enum ClimateScenarioFiltersInputType {
  CHECKBOXES,
  RADIOS,
  TOGGLE_BUTTONS,
  DROPDOWN,
}

export interface ClimateScenarioFiltersMapped {
  key: string;
  filter: ClimateTableDataVariablesItem;
  options: {
    key: string;
    label: string;
  }[];
  inputType: ClimateScenarioFiltersInputType;
  isWide: boolean;
}

export interface ClimateScenarioFiltersProps {
  defaultFilters?: ClimateTableDataVariables;
  defaultValues?: { [key: string]: string | string[] };
  filters: ClimateScenarioFiltersMapped[];
  form: UseFormReturn<{
    [key: string]: string | string[];
  }>;
  isLoading?: boolean;
  refetch?: QueryObserverBaseResult["refetch"];
  withDownload?: boolean;
  withRefresh?: boolean;
  filterData?: any;
}
