import { useQuery } from "react-query";

import Container from "@components/utils/container/Container";
import Paths from "@constants/Paths";
import Block from "@components/atoms/block/Block";
import List, { ListItem } from "@components/atoms/list/List";
import Heading from "@components/atoms/heading/Heading";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";

import styles from "./climateScenarioDownloads.module.css";
import CollapsibleBlock from "@components/molecules/collapsibleBlock/CollapsibleBlock";
import { useState } from "react";
import { Stack } from "@mui/material";
import Tooltip from "@components/atoms/tooltip/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import ScenarioTooltip from "../climateScenarioFilters/tooltips/Scenario";
import YearsTooltip from "../climateScenarioFilters/tooltips/Years";
import { ContainerMaxWidth } from "@components/utils/container/Container.types";

interface DownloadItem {
  key: string;
  size: number;
  formatted_size: string;
  last_modified: Date;
  url: string;
}

const ClimateScenarioDownloads = () => {
  const [explanationOpen, setExplanationOpen] = useState(false);
  const { data, isLoading, isError } = useQuery<DownloadItem[]>(
    ["climateScenarioDownloads"],
    async () => {
      const response = await fetch(Paths.CLIMATE_DOWNLOADS, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      return await response.json();
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleClick = (url: string, filename: string) => {
    const fakeLink = document.createElement("a");
    fakeLink.href = url;
    fakeLink.setAttribute("download", filename);
    document.body.appendChild(fakeLink);
    fakeLink.click();
    fakeLink.remove();
  };

  return (
    <Container maxWidth={ContainerMaxWidth.MAX}>
      <Stack direction="column" gap={4}>
        <CollapsibleBlock
          title="Uitleg Modeluitvoer"
          isOpen={explanationOpen}
          setOpen={setExplanationOpen}
          img="/downloads-hero.png"
        >
          <p>
            De modeluitvoer betreft 8 maal een set aan modeltijdreeksen voor
            klimaatverandering voor een periode van 30 jaar (in tijdstappen van
            1 dag, de roosterpunten liggen in West-Oost richting 0,125 graden
            uit elkaar, en in Noord-Zuid richting 0,1 graden) voor het
            referentieklimaat en per scenario voor de tijdshorizonten in de
            toekomst.
          </p>
          <p>
            Per klimaatvariabele, scenario{" "}
            <Tooltip tooltip={ScenarioTooltip}>
              <InfoOutlined sx={{ fontSize: "16px" }} />
            </Tooltip>{" "}
            en tijdshorizon{" "}
            <Tooltip tooltip={YearsTooltip}>
              <InfoOutlined sx={{ fontSize: "16px" }} />
            </Tooltip>
            is één datafile beschikbaar met acht keer dertig jaar aan
            modeluitvoer. Een uitgebreide toelichting vind je{" "}
            <a href="https://www.knmi.nl/kennis-en-datacentrum/achtergrond/modeluitvoer-knmi-23-klimaatscenario-s">
              hier
            </a>
            .
          </p>
          Beschikbare variabelen:
          <ul>
            <li>Temperatuur (tas)</li>
            <li>Maximum Temperatuur (tasmax)</li>
            <li>Minimum Temperatuur (tasmin)</li>
            <li>Neerslag (pr)</li>
            <li>Wind (sfcwind)</li>
            <li>Relatieve Luchtvochtigheid (hurs)</li>
            <li>Potentiële Evapotranspiratie (pet)</li>
            <li>Zonnestraling (rsds)</li>
          </ul>
          <p>
            Op 8 december 2023 werd{" "}
            <a href="https://dataplatform.knmi.nl/dataset/knmi23-user-friendly-racmo-1-0">
              versie 1
            </a>{" "}
            vervangen door{" "}
            <a href="https://dataplatform.knmi.nl/dataset/knmi23-user-friendly-racmo-2-0">
              versie 2
            </a>
            . In versie 2 wordt heel Nederland overdekt met modeldata.
          </p>
        </CollapsibleBlock>
        <Block>
          <Heading level={1}>Downloads</Heading>

          {isLoading && <p className={styles.label}>Loading...</p>}
          {!isLoading && isError && (
            <p className={styles.label}>
              Something went wrong retrieving the data.
            </p>
          )}
          {data && (
            <List
              items={data.map((download) => (
                <ListItem
                  key={download.key}
                  onClick={() => handleClick(download.url, download.key)}
                  item={
                    <>
                      <span>{download.key}</span>
                      <Box sx={{ display: "inline-flex" }} gap={1}>
                        <DownloadIcon color="primary" />
                        <span>{download.formatted_size}</span>
                      </Box>
                    </>
                  }
                />
              ))}
            />
          )}
        </Block>
      </Stack>
    </Container>
  );
};

export default ClimateScenarioDownloads;
