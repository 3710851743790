export enum ContainerMaxWidth {
  DEFAULT,
  SMALL,
  LARGE,
  MAX
}

export interface ContainerProps {
  className?: string
  maxWidth?: ContainerMaxWidth
}