import { Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { ClimateScenarioFiltersMapped } from "@components/organisms/climateScenarioFilters/ClimateScenarioFilters.types";
import { useEffect } from "react";

interface ClimateScenariofiltersProps {
  filters: ClimateScenarioFiltersMapped[];
  forceDisable: string | boolean;
  forceEnable: string | boolean;
  form: UseFormReturn<{
    [key: string]: string | string[];
  }>;
}

const ClimateScenariofilters = ({
  filters,
  forceDisable,
  forceEnable,
  form,
}: ClimateScenariofiltersProps) => {
  useEffect(() => {
    if (forceDisable || forceEnable) {
      form.setValue(filters[0].key, "waarde");
    }
  }, [forceDisable, forceEnable]);
  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      {filters.length > 0 &&
        filters.map(({ key, options }) => (
          <Controller
            name={key}
            key={key}
            control={form.control}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                exclusive
                onChange={(_, value) => {
                  if(value) {
                    form.setValue(key, value);
                  }
                }}
              >
                {options.map((option) => (
                  <ToggleButton
                    key={option.key}
                    value={option.key}
                    disabled={forceDisable === option.key}
                    {...(forceEnable === option.key ? { selected: true } : {})}
                  >
                    {option.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
        ))}
    </Stack>
  );
};

export default ClimateScenariofilters;
