import { ReactNode } from "react"

import styles from './list.module.css'

interface ListProps {
  items: ReactNode[]
}

interface ListItemProps {
  item: ReactNode
  onClick: () => void
}

const List = ({ items }: ListProps) => {
  return (
    <ul className={styles.list}>
      {items}
    </ul>
  )
}

export const ListItem = ({ item, onClick }: ListItemProps) => {
  return (
    <li
      className={styles.item}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={e => e.key === 'Enter' && onClick()}
    >
      {item}
    </li>
  )
}

export default List