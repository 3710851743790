const NaturalVariationsTooltip = (
  <>
    <p>
      De getallen voor klimaatverandering zijn per tijdshorizon gemiddeld over
      een periode van 30 jaar. Binnen die periode van 30 jaar komen ‘grote’
      variaties van jaar tot jaar voor. Om een indruk te krijgen hoe groot de
      verschillen tussen de jaren in zo’n 30 jarige periode zijn, vink dan ‘Jaar
      op jaar’ aan. Dit geeft de grenzen weer waarbinnen 90% van de jaarwaarden
      valt.
    </p>
      <p>
      Gemiddeld valt dus 9 op de 10 jaar binnen deze range en 1 op de 10 jaar
      valt hier nog buiten. Vink ’30 jaar gemiddeldes’ aan indien je wilt weten
      hoe groot de natuurlijke variaties zijn in gemiddeldes over 30 jaar. Er is
      maar 10% kans dat het 30-jarig gemiddelde buiten deze grenzen valt.
    </p>
  </>
);

export default NaturalVariationsTooltip;
