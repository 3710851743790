import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import Header from "@components/layout/header/Header";
import Menu from "@components/layout/menu/Menu";
import Body from "@components/layout/body/Body";
import Theme from "@components/layout/theme/Theme";
import Footer from "@components/layout/footer/Footer";
import ClimateScenarioTable from "@components/organisms/climateScenarioTable/ClimateScenarioTable";
import ClimateScenarioMap from "@components/organisms/climateScenarioMap/ClimateScenarioMap";
import ClimateScenarioSeries from "@components/organisms/climateScenarioSeries/ClimateScenarioSeries";
import ClimateScenarioDownloads from "@components/organisms/climateScenarioDownloads/ClimateScenarioDownloads";
import ClimateScenarioHome from "@components/organisms/climateScenarioHome/climateScenarioHome";

const App = () => {
  const queryClient = new QueryClient();

  const RootElement = () => (
    <Theme>
      <Header />
      <Menu />
      <Body>
        <Outlet />
      </Body>
      <Footer />
    </Theme>
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootElement />,
      errorElement: <p>Not found</p>,
      children: [
        {
          path: "",
          element: <ClimateScenarioHome />,
        },
        // {
        //   path: 'kaart',
        //   element: <ClimateScenarioMap />
        // },
        {
          path: "downloads",
          element: <ClimateScenarioDownloads />,
        },
        {
          path: "tijdreeks",
          element: <ClimateScenarioSeries />,
        },
        {
          path: "kerncijfers",
          element: <ClimateScenarioTable />,
        },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;
