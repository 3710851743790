import { Link } from "react-router-dom";

import Container from "@components/utils/container/Container";

import styles from "./footer.module.css";

const Footer = () => {
  const links = [
    {
      id: "copyright",
      title: "Copyright",
      link: "https://www.knmi.nl/copyright",
    },
    {
      id: "privacy",
      title: "Privacy",
      link: "https://www.knmi.nl/privacy",
    },
    {
      id: "cookies",
      title: "Cookies",
      link: "https://www.knmi.nl/cookies",
    },
    {
      id: "i18n",
      title: "Toegankelijkheid",
      link: "https://www.knmi.nl/toegankelijkheid",
    },
    {
      id: "contact",
      title: "Contact",
      link: "https://www.knmi.nl/over-het-knmi/contact/contactformulier",
    },
  ];

  return (
    <div className={styles.component}>
      <Container className={styles.container}>
        {links.map((link) => (
          <Link
            to={link.link}
            key={link.id}
            className={styles.link}
            target="_blank"
          >
            {link.title}
          </Link>
        ))}
      </Container>
    </div>
  );
};

export default Footer;
