import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { ContainerMaxWidth, ContainerProps } from './Container.types'
import styles from './container.module.css'

const Container = ({
  className,
  maxWidth = ContainerMaxWidth.DEFAULT,
  children
}: PropsWithChildren<ContainerProps>) => {
  return <div className={classNames(
    className,
    styles.component,
    {
      [styles.maxWidthSmall]: maxWidth === ContainerMaxWidth.SMALL,
      [styles.maxWidthLarge]: maxWidth === ContainerMaxWidth.LARGE,
      [styles.maxWidthMax]: maxWidth === ContainerMaxWidth.MAX
    }
  )}>
    {children}
  </div>
}

export default Container