import Container from "@components/utils/container/Container";
import Paths from "@constants/Paths";
import useClimateScenarioFilters from "hooks/useClimateScenarioFilters";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  ClimateTableDataVariables,
  variableMapping,
} from "types/ClimateTableData";
import Stack from "@mui/material/Stack";
import ClimateScenarioFilters from "@components/organisms/climateScenarioFilters/ClimateScenarioFilters";
import CollapsibleBlock from "@components/molecules/collapsibleBlock/CollapsibleBlock";
import useDebouncedQuery from "hooks/useDebounce";
import TimeScaleChart from "./TimeScaleChart";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import Block from "@components/atoms/block/Block";
import { Controller } from "react-hook-form";
import styles from "./climateScenarioSeries.module.css";
import { transformDate } from "@components/utils/transformDate";
import { ContainerMaxWidth } from "@components/utils/container/Container.types";

const ClimateScenarioSeries = ({}) => {
  const [defaultFilters, setDefaultFilters] = useState<
    ClimateTableDataVariables | undefined
  >(undefined);
  const [filterData, setFilterData] = useState<
    { [key: string]: string[] | string } | undefined
  >(undefined);
  const [explanationOpen, setExplanationOpen] = useState(false);
  const [climateScenarioVariable, setClimateScenarioVariable] =
    useState<string>("temp");

  const { defaultValues, baseFilters, filterForm } = useClimateScenarioFilters({
    defaultFilters,
  });

  // Update filter state to update filterQuery
  useEffect(() => {
    const sub = filterForm.watch((values) => {
      if (!defaultFilters) return;
      setFilterData(values);
    }, defaultValues);
    return () => sub.unsubscribe();
  }, [filterForm.watch, defaultFilters]);

  // Filter query is used to trigger a new api call if filters are changed
  const filterQuery = useMemo(() => {
    const values = Object.values(filterData ?? {});
    if (values.length === 0) return "";

    return values
      .map((value) => (Array.isArray(value) ? value.join(",") : value))
      .join("-");
  }, [filterData]);

  const onClimateScenarioVariableChange = (event: any) => {
    filterForm.setValue("climate_variables", event.target.value);
    setClimateScenarioVariable(event.target.value);
  };

  const debouncedFilterQuery = useDebouncedQuery(filterQuery, 300);
  const { data, isLoading, isError, refetch } = useQuery(
    ["climateScenarioSerieData", debouncedFilterQuery],
    async () => {
      let response;
      if (!filterData) {
        // No filters changed - fetch default
        response = await fetch(Paths.CLIMATE_SERIES_DATA, {
          method: "GET",
        });
      } else {
        // Filters are changed - fetch with filters
        const data: {
          [key: string]:
            | string
            | {
                [key: string]: boolean | string;
              };
        } = {};
        // Map filter data to usable data for api call
        Object.entries(filterData).forEach(([key, values]) => {
          if (Array.isArray(values)) {
            data[key] = {};
            values.forEach((value) => (data[key][value] = true));
          } else {
            data[key] = values;
          }
        });

        response = await fetch(Paths.CLIMATE_SERIES_DATA, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            table_variables: data,
          }),
        });
      }

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      return await response.json();
    }
  );

  // Update the filter defaults once when the first call is done
  useEffect(() => {
    setDefaultFilters((prevDefaults) => {
      if (prevDefaults === undefined && data !== undefined) {
        return data.table_variables;
      }

      return prevDefaults;
    });
  }, [data, setDefaultFilters]);

  return (
    <Container maxWidth={ContainerMaxWidth.MAX}>
      <Stack direction="column" gap={4}>
        <CollapsibleBlock
          title="Uitleg Getransformeerde tijdreeksen"
          isOpen={explanationOpen}
          setOpen={setExplanationOpen}
          img="/tijdreeksen-hero.png"
        >
          <>
            <p>
              De getransformeerde reeksen zijn reeksen met dagwaarden over een
              periode van 30 jaar voor een tijdshorizon in de toekomst. <br />
              De getransformeerde tijdreeksen geven zo goed mogelijk aan hoe de
              waarnemingen passen bij het klimaat in de toekomst volgens de
              KNMI-klimaatscenario's. Ze geven daarmee een indruk van hoe het
              weer in de toekomst van dag tot dag eruit kan zien. Ze zijn géén
              voorspelling van het weer in de toekomst op een bepaalde dag of in
              een bepaald jaar.
            </p>
            <p>
              Stel je eigen selectie samen door in het menu Filters de voor jou
              relevante scenario’s en tijdshorizonten aan te vinken. De
              bestanden bevatten standaard alle beschikbare variabelen:
              neerslag, gemiddelde-, maximum- en minimumtemperatuur,
              zonnestraling, wind en relatieve vochtigheid.
            </p>
            <p>
              Selecteer een station in de lijst (per provincie) en klik op
              Download data.
            </p>
            <p>
              <a
                href="https://cdn.knmi.nl/system/data_center_publications/files/000/071/904/original/KNMI23_climate_scenarios_guidance_on_the_use_of_time_series_TR408.pdf?1696590687"
                target="_blank"
              >
                Handleiding voor gebruik (PDF, EN)
              </a>
            </p>
          </>
        </CollapsibleBlock>
        {defaultFilters !== undefined && (
          <>
            <ClimateScenarioFilters
              defaultValues={defaultValues}
              filters={baseFilters.filter(
                (filter) =>
                  filter?.key !== "date_range" &&
                  filter?.key !== "climate_variables"
              )}
              form={filterForm}
              isLoading={isLoading}
              refetch={refetch}
              withDownload
              filterData={filterData}
            />
            <Block>
              <div className={styles.graphData}>
                <span>
                  {
                    defaultFilters?.station.options[
                      filterData?.station as string
                    ]
                  }
                </span>
                <span>
                  Tonen gekozen periode:{" "}
                  {filterData?.date_range[0]
                    ? transformDate(filterData?.date_range[0])
                    : transformDate(
                        defaultFilters?.date_range.defaults[0]
                      )}{" "}
                  -{" "}
                  {filterData?.date_range[1]
                    ? transformDate(filterData?.date_range[1])
                    : transformDate(defaultFilters?.date_range.defaults[1])}
                </span>
                <span className={styles.type}>
                  {variableMapping[climateScenarioVariable]}
                </span>
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  marginBottom: "80px",
                }}
              >
                <div>
                  <Controller
                    name="date_range[0]"
                    key="date_range[0]"
                    control={filterForm.control}
                    render={({ field }) => (
                      <TextField
                        label="Begin datum"
                        variant="outlined"
                        type="date"
                        defaultValue={defaultFilters?.date_range.defaults[0]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          filterForm.setValue("date_range", [
                            e.target.value,
                            filterData?.date_range[1] ??
                              defaultFilters?.date_range.defaults[1],
                          ])
                        }
                        sx={{ marginRight: "12px" }}
                      />
                    )}
                  />
                  <Controller
                    name="date_range[1]"
                    key="date_range[1]"
                    control={filterForm.control}
                    render={({ field }) => (
                      <TextField
                        label="Eind datum"
                        variant="outlined"
                        type="date"
                        defaultValue={defaultFilters?.date_range.defaults[1]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          filterForm.setValue("date_range", [
                            filterData?.date_range[0] ??
                              defaultFilters?.date_range.defaults[0],
                            e.target.value,
                          ])
                        }
                      />
                    )}
                  />
                </div>
                <Controller
                  name="climate_variables"
                  key="climate_variables"
                  control={filterForm.control}
                  render={({ field }) => (
                    <Select
                      labelId="climate-scenario-variables"
                      id="climate-scenario-variables"
                      value={climateScenarioVariable}
                      onChange={onClimateScenarioVariableChange}
                    >
                      {Object.keys(
                        defaultFilters.climate_variables.options
                      ).map((variable) => (
                        <MenuItem value={variable} key={variable}>
                          {defaultFilters.climate_variables.options[variable]}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>

              <TimeScaleChart filterData={filterData ?? defaultValues} />
            </Block>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default ClimateScenarioSeries;
