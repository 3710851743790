import { useMemo } from "react";
import {
  RowData,
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

import { TableProps } from "./Table.types";
import styles from "./table.module.css";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    isSticky?: boolean;
    forceRowSpan?: boolean;
    cellFill?: boolean;
    colName?: string;
  }
}

const Table = <T,>({ data, columns, className }: TableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
  });

  const rowSpanAcc = useMemo(() => {
    const acc: { [key: string]: { [index: number]: number } } = {};
    data.forEach((row, index) => {
      Object.entries(row).forEach(([key, value]) => {
        if (index === 0) {
          acc[key] = {
            [index]: 1,
          };
          return;
        }

        const equalsPreviousRowValue = data[index - 1][key] === value;
        if (equalsPreviousRowValue) {
          const [lastIndex, lastValue] = Object.entries(acc[key]).findLast(
            (i) => i
          );
          acc[key][lastIndex] = lastValue + 1;
        } else {
          acc[key][index] = 1;
        }
      });
    });
    return acc;
  }, [data]);


  const getColNameToUseForClass = (cells, currentIndex, colName) => {
    if (colName?.startsWith('Bandbreedte') || colName?.startsWith('Variaties')) {
      // For these specific columns use the column name from the previous column (which contains e.g. 'Ld') for its class
      const previousCell = cells[currentIndex - 1];
      if (previousCell) {
        const previousColName = previousCell.column.columnDef.header;
        // do this recursively because multiple the previous column itself could also be one without e.g. 'Ld'. So go back until that is not the case
        return getColNameToUseForClass(cells, currentIndex - 1, previousColName);
      }
    }

    return colName;
  };


  const getCellClasses = (cells, currentIndex, colName, classNamePrefix) => {
    const colNameToUseForClass = getColNameToUseForClass(cells, currentIndex, colName);

    const stylesObj = {
      [styles[`${classNamePrefix}Ld`]]: colNameToUseForClass?.indexOf("Ld") >= 0,
      [styles[`${classNamePrefix}Ln`]]: colNameToUseForClass?.indexOf("Ln") >= 0,
      [styles[`${classNamePrefix}Md`]]: colNameToUseForClass?.indexOf("Md") >= 0,
      [styles[`${classNamePrefix}Mn`]]: colNameToUseForClass?.indexOf("Mn") >= 0,
      [styles[`${classNamePrefix}Hd`]]: colNameToUseForClass?.indexOf("Hd") >= 0,
      [styles[`${classNamePrefix}Hn`]]: colNameToUseForClass?.indexOf("Hn") >= 0,
    };

    return stylesObj;
  };


  return (
    <>
      <table className={classNames(styles.table, className)}>
        <thead className={styles.tableHead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={styles.headRow}>
              {headerGroup.headers.map((header, headerIndex) => {
                const colName = header.column.columnDef.header;
                const thClasses = classNames(styles.bodyCell, {
                  [styles.sticky]: header.column.columnDef.meta?.isSticky,
                  [styles.fill]: header.column.columnDef.meta?.cellFill,
                }, getCellClasses(headerGroup.headers, headerIndex, colName, "head"))

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={thClasses}
                    style={{
                      minWidth: header.column.getSize(),
                      left: header.column.getStart(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(colName, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>


        <tbody className={styles.tableBody}>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id} className={styles.bodyRow}>
              {row.getVisibleCells().map((cell, cellIndex) => {
                const rowSpan =
                  rowSpanAcc[cell.column.columnDef.id]?.[rowIndex];
                const shouldHaveRowSpan =
                  cell.column.columnDef.meta?.forceRowSpan;
                if (!rowSpan && shouldHaveRowSpan) return undefined;

                let colSpan = 1;
                if (cellIndex <= 2) {
                  const cells = row.getAllCells().slice(cellIndex, 3);
                  if (!cells[0].getValue()) return undefined;
                  const numberOfEmptyCells = cells.filter(
                    (c) => c.getValue() === undefined
                  ).length;
                  colSpan += numberOfEmptyCells;
                }

                const tdClasses = classNames(styles.bodyCell, {
                  [styles.sticky]: cell.column.columnDef.meta?.isSticky && colSpan === 1,
                  // NOTE: in case trend row cells do need to be sticky
                  // [styles.ignoreStickyBackground]: cell.column.columnDef.meta?.isSticky && colSpan > 1,
                  [styles.fill]: cell.column.columnDef.meta?.cellFill,
                  [styles.season]: cell.column.columnDef.header === "Seizoen",
                  [styles.variable]: cell.column.columnDef.header === "Variabele",
                }, getCellClasses(row.getVisibleCells(), cellIndex, cell.column.columnDef.meta?.colName, "cell"))

                return (
                  <td
                    rowSpan={shouldHaveRowSpan ? rowSpan : 1}
                    colSpan={colSpan}
                    key={cell.id}
                    className={tdClasses}
                    style={{
                      minWidth: cell.column.getSize(),
                      left: cell.column.getStart(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>

        <tfoot>
          {table.getFooterGroups().map(
            (footerGroup) =>
              footerGroup.id !== "0" && (
                <tr className={footerGroup.id} key={footerGroup.id}>
                  {footerGroup.headers.map((footer) => (
                    <td key={footer.id}>
                      {flexRender(
                        footer.column.columnDef.footer,
                        footer.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              )
          )}
        </tfoot>
      </table>
    </>
  );
};

export default Table;
