const SeasonTooltip = (
  <>
    <p>Zowel de gemiddelde voor het hele jaar als voor de vier seizoenen worden getoond.</p>
    <ul>
      <li>Winter: december, januari, februari</li>
      <li>Lente: maart, april, mei</li>
      <li>Zomer: juni, juli, augustus</li>
      <li>Herst: september, oktober, november</li>
    </ul>
  </>
)

export default SeasonTooltip