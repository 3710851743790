import { ReactNode } from 'react'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import Tooltip from '@components/atoms/tooltip/Tooltip'

import styles from './filterLabel.module.css'

interface FilterLabelProps {
  label: string
  tooltip?: ReactNode
}

const FilterLabel = ({ label, tooltip }: FilterLabelProps) => {
  return (
    <div className={styles.filterLabel}>
      {tooltip && (
        <span className={styles.tooltip}>
          <Tooltip tooltip={tooltip}>
            <InfoOutlined />
          </Tooltip>
        </span>
      )}
      <span className={styles.label}>{label}</span>
    </div>
  )
}

export default FilterLabel