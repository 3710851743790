import React, { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Chart, ChartOptions, registerables } from "chart.js";
import * as dateFnsAdapter from "chartjs-adapter-date-fns";
import parser from "query-string-parser";
import useDebouncedQuery from "hooks/useDebounce";
import { useQuery } from "react-query";
import HourglassBottomIcon from "@mui/icons-material/Loop";

Chart.register(...registerables);
Chart.register(dateFnsAdapter);

// Vertical dashed tooltip line
const customLinesPlugin = {
  id: "customLines",
  afterDatasetsDraw: (chart, args, pluginOptions) => {
    const tooltipElements = chart.getActiveElements();
    if (tooltipElements.length && chart.tooltip.opacity > 0) {
      const { x } = tooltipElements[0].element;
      const ctx = chart.ctx;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      // Draw the line
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash(pluginOptions.dashPattern);
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = pluginOptions.lineWidth;
      ctx.strokeStyle = pluginOptions.color;
      ctx.stroke();
      ctx.restore();
    }
  },
};

const customBackgroundsPlugin = {
  id: "customBackground",
  afterDraw: (chart) => {
    const chartCanvas = chart.canvas;
    const chartContext = chartCanvas.getContext("2d");
    const chartArea = chart.chartArea;

    // Draw alternating backgrounds along the y-axis
    let alternateBackground = true;
    const barWidth = (chartArea.right - chartArea.left) / 10;

    for (let i = 0; i < 10; i++) {
      const xPos = chartArea.left + i * barWidth;
      if (alternateBackground) {
        const originalFillStyle = chartContext.fillStyle;

        chartContext.fillStyle = "rgba(0, 0, 0, 0.1)"; // Adjust background color
        chartContext.fillRect(
          xPos,
          chartArea.top,
          barWidth,
          chartArea.bottom - chartArea.top
        );

        chartContext.fillStyle = originalFillStyle;
      }
      alternateBackground = !alternateBackground;
    }
  },
};

Chart.register(customLinesPlugin);
Chart.register(customBackgroundsPlugin);

type TimeScaleChartProps = {
  filterData?: any;
};

const TimeScaleChart: React.FC = ({ filterData }: TimeScaleChartProps) => {
  useEffect(() => {
    if (filterData) {
      refetch();
    }
  }, [filterData]);

  const filterQuery = useMemo(() => {
    const values = Object.values(filterData ?? {});
    if (values.length === 0) return "";

    return values
      .map((value) => (Array.isArray(value) ? value.join(",") : value))
      .join("-");
  }, [filterData]);

  const debouncedFilterQuery = useDebouncedQuery(filterQuery, 0);

  const { data, isLoading, isError, refetch } = useQuery(
    ["climateScenarioTimeScaleChart", debouncedFilterQuery],
    async () => {
      let response;
      if (!filterData) return null;
      const data: {
        [key: string]:
          | string
          | {
              [key: string]: boolean | string;
            };
      } = {};

      Object.entries(filterData).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          data[key] = {};
          values.forEach((value) => (data[key][value] = true));
        } else {
          data[key] = values;
        }
      });

      const paramsObject = {
        series_variables: {
          ...filterData,
        },
      };

      const searchParams = parser.toQuery(paramsObject);

      response = await fetch(
        `/api/v1/climate-series-graph.json?${searchParams}`
      );

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const res = await response.json();

      const formattedData = {
        labels: res.graph.labels,
        datasets: res.graph.datasets.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data,
          fill: false,
          backgroundColor: dataset.color,
          borderColor: dataset.color,
          hidden: false,
        })),
        metadata: res.graph.metadata,
      };

      return formattedData;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const options: ChartOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: function (chart) {
            const [, dateRange] = Object.entries(filterData).find(
              ([key]) => key === "date_range"
            );
            const startDate = new Date(dateRange[0]);
            const endDate = new Date(dateRange[1]);
            const diffInDays = Math.floor(
              (endDate - startDate) / (1000 * 60 * 60 * 24)
            );

            // manually chosen numbers to switch from day to week to month
            if (diffInDays < 80) {
              return "day";
            } else if (diffInDays < 250) {
              return "week";
            } else {
              return "month";
            }
          },
        },
        grid: {
          drawOnChartArea: false, // this will remove vertical lines
        },
      },
      y: {
        position: "left",
        grid: {
          drawOnChartArea: true, // this will keep horizontal lines
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderColor: "rgba(66, 66, 66, 0.1)",
        padding: 8,
        borderWidth: 1,
        boxPadding: 8,
        titleColor: "#FFF",
        titleFont: {
          family: "RO Sans",
          size: 18,
        },
        bodyColor: "rgba(255, 255, 255, 0.8)",
        bodyFont: {
          family: "RO Sans",
          size: 14,
        },
        callbacks: {
          label: function (context) {
            const label = context.dataset.label;
            const value = context.parsed.y;
            return `${label}: ${value}${data.metadata.climate_variable_info.unit_short}`;
          },
          title: function (context) {
            const label = context[0].label;
            const stripTime = label.split(",");
            return `${stripTime[0]}, ${stripTime[1]}`;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          generateLabels: function (chart) {
            const datasets = chart.data.datasets;
            const legendItems = [];

            datasets.forEach(function (dataset, i) {
              const isHidden = dataset.hidden === null ? false : dataset.hidden;
              const color = isHidden ? "rgba(0,0,0,0.5)" : dataset.borderColor;

              legendItems.push({
                text: dataset.label,
                fillStyle: color,
                strokeStyle: color,
                hidden: isHidden,
                datasetIndex: i,
                color: color,
                backgroundColor: color,
              });
            });

            return legendItems;
          },
        },
        onClick: function (_e, legendItem) {
          const index = legendItem.datasetIndex;
          const chart = this.chart;

          chart.data.datasets[index].hidden =
            !chart.data.datasets[index].hidden;

          chart.update();
        },
      },
      customLines: {
        color: "#666",
        lineWidth: 1,
        dashPattern: [5, 5],
      },
    },
  };

  if (isLoading) {
    return (
      <div>
        Aan het laden...{" "}
        <HourglassBottomIcon
          sx={{
            width: 16,
            height: 16,
            animation: "spin 2s linear infinite",
            keyframes: {
              "@keyframes spin": {
                from: { transform: "rotate(0deg)" },
                to: { transform: "rotate(360deg)" },
              },
            },
          }}
        />
        .
      </div>
    );
  }

  if (isError) {
    return <div>Er ging iets mis, probeer het opnieuw.</div>;
  }

  return (
    <>
      {data !== null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ transform: "rotate(270deg)", textAlign: "center" }}>
            {data.metadata.climate_variable_info.unit_short}
          </span>
          <Line data={data} options={options as ChartOptions<"line">} />
        </div>
      ) : null}
    </>
  );
};

export default TimeScaleChart;
