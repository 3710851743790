import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { Controller } from "react-hook-form";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";
import LoopIcon from "@mui/icons-material/Loop";

import FilterLabel from "@components/atoms/filterLabel/FilterLabel";
import { Button } from "@mui/material";
import CollapsibleBlock from "@components/molecules/collapsibleBlock/CollapsibleBlock";
import getClimateScenarioFilterLabel from "@components/organisms/climateScenarioFilters/GetClimateScenarioFilterLabel";
import getClimateScenarioFilterTooltip from "@components/organisms/climateScenarioFilters/GetClimateScenarioFilterTooltip";

import {
  ClimateScenarioFiltersInputType,
  ClimateScenarioFiltersProps,
} from "./ClimateScenarioFilters.types";
import styles from "./climateScenarioFilters.module.css";
import Paths from "@constants/Paths";
import { DownloadFormats } from "@components/molecules/downloadDataDropdown/DownloadDataDropdown";
import DownloadButton from "@components/molecules/downloadButton/DownloadButton";
import { Close } from "@mui/icons-material";
import Map from "./map/Map";
import Popup from "@components/molecules/popup/Popup";
import LocationFilter from "./locationFilter/LocationFilter";

const ClimateScenarioFilters = ({
  defaultValues,
  filters,
  form,
  isLoading,
  refetch,
  withDownload = false,
  withRefresh = false,
  filterData,
}: ClimateScenarioFiltersProps) => {
  const [isOpen, setOpen] = useState(true);
  const [isMapOpen, setMapOpen] = useState(false);

  // Add or remove checkbox based on previously selected options
  const handleCheck = (key: string, option: string) => {
    const values = form.getValues();
    const checkedValues = values[key];
    if (checkedValues === undefined) return [option];

    if (Array.isArray(checkedValues) && checkedValues.includes(option)) {
      return checkedValues.filter((o) => o !== option);
    }

    return filters
      .find((x) => x.key === key)
      .options.filter((f) => checkedValues.includes(f.key) || f.key === option)
      .map((f) => f.key);
  };

  const [forcedLoading, setForcedLoading] = useState(false);
  const buttonIsLoading = useMemo(() => {
    return isLoading || forcedLoading;
  }, [isLoading, forcedLoading]);

  // force loading state for 500ms
  useEffect(() => {
    let timeout: number;
    const sub = form.watch(() => {
      clearTimeout(timeout);
      setForcedLoading(true);
      timeout = setTimeout(() => {
        setForcedLoading(false);
      }, 500);
    });
    return () => {
      setForcedLoading(false);
      clearTimeout(timeout);
      sub.unsubscribe();
    };
  }, [form.watch, setForcedLoading]);

  const handleRefreshClick = useCallback(() => {
    refetch();
    setOpen(false);
  }, [refetch, setOpen]);

  const isFilterSelected = useMemo(() => {
    if (!filterData) return false;

    return !Object.values(filterData).some((value) => {
      if (value === undefined) return true; // Condition met: undefined
      if (Array.isArray(value)) {
        return value.length === 0; // Condition met: empty array
      }
      return value === ""; // Condition met: empty string
    });
  }, [filterData, form.watch()]);

  const setStation = (station: string) => {
    form.setValue("station", station);
  };

  return (
    <CollapsibleBlock
      title="Filters"
      isOpen={isOpen}
      setOpen={setOpen}
      className={styles.block}
    >
      <>
        <div className={styles.grid} id="climate-scenario-filters">
          {filters.map(({ key, options, inputType, isWide }) => {
            if (inputType === ClimateScenarioFiltersInputType.DROPDOWN) {
              return (
                <LocationFilter
                  form={form}
                  options={options}
                  keyField={key}
                  withDownload={withDownload}
                  setMapOpen={setMapOpen}
                  styles={styles}
                  key={key}
                  defaultValues="550_De-Bilt_utrecht"
                />
              );
            }
            return (
              <div
                key={key}
                className={classNames(styles.filterBlock, {
                  [styles.filterBlockWide]: isWide,
                })}
              >
                <FormControl fullWidth>
                  <FilterLabel
                    label={getClimateScenarioFilterLabel(key)}
                    tooltip={getClimateScenarioFilterTooltip(key)}
                  />
                  <FormGroup row className={styles.filterGroup}>
                    {inputType === ClimateScenarioFiltersInputType.CHECKBOXES &&
                      options.map((option) => {
                        return (
                          <Controller
                            key={option.key}
                            name={key}
                            control={form.control}
                            render={({ field }) => (
                              <FormControlLabel
                                label={option.label}
                                control={
                                  <Checkbox
                                    {...field}
                                    defaultChecked={defaultValues[
                                      key
                                    ]?.includes(option.key)}
                                    onChange={() =>
                                      form.setValue(
                                        key,
                                        handleCheck(key, option.key)
                                      )
                                    }
                                    value={option.key}
                                  />
                                }
                              />
                            )}
                          />
                        );
                      })}

                    {inputType === ClimateScenarioFiltersInputType.RADIOS && (
                      <Controller
                        name={key}
                        control={form.control}
                        render={({ field }) => (
                          <RadioGroup row>
                            {options.map((option) => (
                              <FormControlLabel
                                key={option.key}
                                label={option.label}
                                control={
                                  <Radio
                                    {...field}
                                    checked={field.value === option.key}
                                    value={option.key}
                                  />
                                }
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    )}
                  </FormGroup>
                </FormControl>
              </div>
            );
          })}
          {withDownload ? (
            <DownloadButton
              baseUrl={Paths.CLIMATE_SERIES_DATA}
              format={DownloadFormats.zip}
              filters={filterData}
              filterKey="series_variables"
              styleOverride={{ mt: "auto" }}
              disabled={!isFilterSelected}
            />
          ) : null}
        </div>
        {withRefresh ? (
          <Stack alignItems="center" mt={4}>
            <Button
              variant="contained"
              color="primary"
              startIcon={buttonIsLoading ? <LoopIcon /> : <RefreshIcon />}
              onClick={() => handleRefreshClick()}
            >
              {buttonIsLoading ? "Loading..." : "Pas toe"}
            </Button>
          </Stack>
        ) : null}
        {isMapOpen &&
          createPortal(
            <Popup>
              <h3>
                Selecteer een station op de kaart
                <Close onClick={() => setMapOpen(false)} />
              </h3>
              <Map
                selectStation={setStation}
                closeMap={() => setMapOpen(false)}
              />
            </Popup>,
            document.body
          )}
      </>
    </CollapsibleBlock>
  );
};

export default ClimateScenarioFilters;
