import { useEffect } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";

interface BaseLayersProps {
  baseLayers: L.Control.LayersObject;
}

const BaseLayers = ({ baseLayers }: BaseLayersProps) => {
  const context = useLeafletContext();

  useEffect(() => {
    // Add base layers
    Object.values(baseLayers).forEach((baseLayer) => {
      baseLayer.addTo(context.map);
    });
  }, [baseLayers]);

  return <></>;
};

export default BaseLayers;
