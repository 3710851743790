export function transformDate(inputDate) {
  // Parse the input date string
  const parts = inputDate.split("-");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  // Create a new Date object with the parsed values
  const transformedDate = new Date(year, month - 1, day);

  // Extract the day, month, and year from the transformed date
  const transformedDay = transformedDate.getDate().toString().padStart(2, "0");
  const transformedMonth = (transformedDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  const transformedYear = transformedDate.getFullYear();

  // Assemble the transformed date string in the desired format
  const result = `${transformedDay}-${transformedMonth}-${transformedYear}`;

  return result;
}
