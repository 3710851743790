import Section from "@components/utils/container/Container";
import Logo from "@components/atoms/logo/Logo";
import Button from "@components/atoms/button/Button";

import styles from "./header.module.css";
import { ArrowForward } from "@mui/icons-material";

const Header = () => {
  return (
    <div className={styles.component}>
      <Section>
        <div className={styles.grid}>
          <a href="/" aria-label="Logo knmi" className={styles.logo}>
            <Logo />
          </a>

          <div className={styles.buttons}>
            <a href="https://www.knmi.nl/klimaatscenarios">
              <Button
                as="div"
                label="Naar knmi.nl"
                icon={<ArrowForward />}
                className={styles.button}
                color="dark"
              />
            </a>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Header;
