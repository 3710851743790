import {
  ClimateScenarioFiltersInputType,
  ClimateScenarioFiltersMapped,
} from "@components/organisms/climateScenarioFilters/ClimateScenarioFilters.types";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  ClimateTableDataVariables,
  ClimateTableDataVariablesItem,
} from "types/ClimateTableData";

interface useClimateScenarioFiltersProps {
  defaultFilters: ClimateTableDataVariables;
}

const useClimateScenarioFilters = ({
  defaultFilters,
}: useClimateScenarioFiltersProps) => {
  const filterForm = useForm();

  const getFilterSettingsPerFilter = useCallback(
    (
      filter: ClimateTableDataVariablesItem
    ): Pick<
      ClimateScenarioFiltersMapped,
      "options" | "inputType" | "isWide"
    > => {
      const options = Array.isArray(filter.options)
        ? filter.options.map((option) => ({ key: option, label: option }))
        : Object.entries(filter.options).map(([key, value]) => ({
            key: key,
            label: value as string,
          }));

      const isWide =
        (filter.multiple && options.length > 6) ||
        (!filter.multiple && options.length > 3);

      let inputType: ClimateScenarioFiltersInputType;

      if (filter.multiple) {
        inputType = ClimateScenarioFiltersInputType.CHECKBOXES;
      }

      if (!filter.multiple && options.length <= 3) {
        inputType = ClimateScenarioFiltersInputType.TOGGLE_BUTTONS;
      }

      if (!filter.multiple && options.length > 3 && options.length <= 10) {
        inputType = ClimateScenarioFiltersInputType.RADIOS;
      }

      if (!filter.multiple && options.length > 10) {
        inputType = ClimateScenarioFiltersInputType.DROPDOWN;
      }
      return { options, inputType, isWide };
    },
    []
  );

  // Split filters into base filters and toggle filters along with extra options
  const [baseFilters, toggleFilters] = useMemo(() => {
    const baseFilters: ClimateScenarioFiltersMapped[] = [];
    const toggleFilters: ClimateScenarioFiltersMapped[] = [];

    Object.entries(defaultFilters ?? {}).forEach(([key, filter]) => {
      const { options, inputType, isWide } = getFilterSettingsPerFilter(filter);
      const filterObject = {
        key,
        filter,
        options,
        inputType,
        isWide,
      };

      if (inputType === ClimateScenarioFiltersInputType.TOGGLE_BUTTONS) {
        toggleFilters.push(filterObject);
      } else {
        baseFilters.push(filterObject);
      }
    });
    return [baseFilters, toggleFilters];
  }, [defaultFilters, getFilterSettingsPerFilter]);

  // Get default values from data
  const defaultValues = useMemo(() => {
    if (defaultFilters === undefined) return undefined;

    const values: {
      [key: string]: string[] | string;
    } = Object.entries(defaultFilters).reduce((acc, [key, filter]) => {
      if (filter.defaults.length > 0) {
        acc[key] = filter.defaults;
      }
      return acc;
    }, {});

    return values;
  }, [defaultFilters]);

  // Set default values of form from mapped data
  useEffect(() => {
    if (defaultValues) {
      filterForm.reset(defaultValues);
    }
  }, [defaultValues]);

  return {
    defaultValues,
    baseFilters,
    toggleFilters,
    filterForm,
  };
};

export default useClimateScenarioFilters;
