import { useMemo } from "react";
import { Link, useMatches } from "react-router-dom";
import classNames from "classnames";

import Container from "@components/utils/container/Container";
import { ContainerMaxWidth } from "@components/utils/container/Container.types";

import { MenuItemProps } from "./Menu.types";
import styles from "./menu.module.css";

const Menu = () => {
  const matches = useMatches();

  const menuItems = useMemo(() => {
    return [
      {
        label: "Home",
        link: "/",
        active: matches[matches.length - 1].pathname === "/",
      },
      {
        label: "Kerncijfers",
        link: "/kerncijfers",
        active: matches.some((match) => match.pathname === "/kerncijfers"),
      },
      // {
      //   label: 'Kaart',
      //   link: '/kaart',
      //   active: matches.some(match => match.pathname === '/kaart'),
      // },
      {
        label: "Modeluitvoer",
        link: "/downloads",
        active: matches.some((match) => match.pathname === "/downloads"),
      },
      {
        label: "Getransformeerde tijdreeksen",
        link: "/tijdreeks",
        active: matches.some((match) => match.pathname === "/tijdreeks"),
      },
    ];
  }, [matches]);

  return (
    <div className={styles.component}>
      <Container
        maxWidth={ContainerMaxWidth.SMALL}
        className={styles.container}
      >
        <div className={styles.items}>
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem.link} {...menuItem} />
          ))}
        </div>
      </Container>
    </div>
  );
};

const MenuItem = ({ label, link, active }: MenuItemProps) => {
  return (
    <Link
      to={link}
      className={classNames(styles.item, {
        [styles.itemActive]: active,
      })}
    >
      {label}
    </Link>
  );
};

export default Menu;
