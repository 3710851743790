import { PropsWithChildren } from "react";

import Block from "@components/atoms/block/Block";
import Heading from "@components/atoms/heading/Heading";

import styles from "./contentBlock.module.css";
import classNames from "classnames";

interface ContentBlockProps {
  image: string;
  title: string;
  className?: string
}

const ContentBlock = ({
  image,
  title,
  children,
  className
}: PropsWithChildren<ContentBlockProps>) => {
  return (
    <Block className={classNames(styles.component, className)}>
      <img src={image} className={styles.image} alt="Knmi grote plaat" />
      <div className={styles.content}>
        <Heading level={2} stylingLevel={3}>
          {title}
        </Heading>
        <p>{children}</p>
      </div>
    </Block>
  );
};

export default ContentBlock;
