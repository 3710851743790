import { PropsWithChildren } from "react"

import styles from './body.module.css'

const Body = ({ children }: PropsWithChildren) => {
  return <div className={styles.body}>
    {children}
  </div>
}

export default Body