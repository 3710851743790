import styles from "./Popup.module.css";

interface PopupProps {
  children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ children }) => {
  return (
    <div className={styles.background}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Popup;
