import { PropsWithChildren } from "react";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";

import Block from "@components/atoms/block/Block";
import Heading from "@components/atoms/heading/Heading";

import { CollapsibleBlockProps } from "./CollapsibleBlock.types";
import styles from "./collapsibleBlock.module.css";

const CollapsibleBlock = ({
  title,
  isOpen,
  setOpen,
  children,
  img,
}: PropsWithChildren<CollapsibleBlockProps>) => {
  return (
    <>
      {img ? <img src={img} /> : null}
      <Block hasImg={Boolean(img)}>
        <Heading
          level={2}
          onClick={() => setOpen((prev) => !prev)}
          tabIndex={0}
          className={styles.heading}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>{title}</span>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </Stack>
        </Heading>
        <Collapse in={isOpen}>
          <Stack pt={4}>{children}</Stack>
        </Collapse>
      </Block>
    </>
  );
};

export default CollapsibleBlock;
