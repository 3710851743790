import { PropsWithChildren } from "react"
import { ThemeProvider, createTheme } from "@mui/material"

import components from './Components'
import palette from './Pallete'

const Theme = ({ children }: PropsWithChildren) => {
  const theme = createTheme({
    palette,
    components,
    typography: {
      fontFamily: "RO Sans"
    }
  })

  return <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
}

export default Theme