import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField, Button } from "@mui/material";
import getClimateScenarioFilterLabel from "../GetClimateScenarioFilterLabel";
import { FmdGood } from "@mui/icons-material";

const LocationFilter = ({
  form,
  options,
  keyField,
  withDownload,
  setMapOpen,
  styles,
  defaultValues,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState({
    key: defaultValues,
    label: "Utrecht - De-Bilt",
  });

  useEffect(() => {
    const currentValue = form.getValues(keyField);

    if (currentValue !== undefined) {
      setAutocompleteValue({
        key: currentValue,
        label: options.find((option) => option?.key === currentValue).label,
      });
      form.setValue(keyField, currentValue);
    }
  }, [form.getValues(keyField)]);

  return (
    <div
      className={`${styles.dropDown} ${withDownload ? styles.download : ""}`}
    >
      <h3>{getClimateScenarioFilterLabel(keyField)}</h3>
      <div className={styles.dropDownGroup}>
        <Controller
          name={keyField}
          control={form.control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              id="Maak een keuze"
              options={options.map((option) => {
                return { label: option.label, key: option?.key };
              })}
              getOptionLabel={(option) => option?.label}
              isOptionEqualToValue={(option, value) => option?.key === value}
              onChange={(e, value) => {
                setAutocompleteValue(value);
                form.setValue(keyField, value?.key);
              }}
              value={autocompleteValue}
              className={styles.dropDownBox}
              renderInput={(params) => (
                <TextField {...params} label={params.id} {...field} />
              )}
            />
          )}
        />
        <span>of</span>
        <Button
          variant="contained"
          onClick={() => setMapOpen(true)}
          sx={{
            height: "56px",
            textTransform: "none",
            justifyContent: "flex-start",
            minWidth: "220px",
          }}
        >
          kies een station op de kaart <FmdGood sx={{ paddingLeft: "10px" }} />
        </Button>
      </div>
    </div>
  );
};

export default LocationFilter;
