import { useEffect, useState } from "react";

const useDebouncedQuery = (filterQuery: string, delay: number) => {
  const [debouncedFilterQuery, setDebouncedFilterQuery] = useState(filterQuery);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedFilterQuery(filterQuery);
    }, delay);

    return () => clearTimeout(debounceTimer);
  }, [filterQuery, delay]);

  return debouncedFilterQuery;
};

export default useDebouncedQuery;
