import { MutableRefObject, ReactNode } from "react";
import styles from "./climateScenarioTable.module.css";
import Heading from "@components/atoms/heading/Heading";

interface ClimateScenarioTablePdfProps {
    targetRef: MutableRefObject<any>;
    children: ReactNode;
    title: string
}

const ClimateScenarioTablePdf = ({
    targetRef,
    children,
    title,
}: ClimateScenarioTablePdfProps) => {
  return (
    <div ref={targetRef} className={styles.pdfTableContainer}>
      <Heading level={1} centered className={styles.heading}>
        Kerncijfers KNMI'23
      </Heading>
      <Heading level={2} centered className={styles.heading}>
        {title}
      </Heading>
      {children}

      <div className={styles.footNote}>
        <div>Extra uitleg</div>
        <ol>
          <li>
            De getallen voor zeespiegel gelden ten opzichte van het gemiddelde
            over 1995-2014.
          </li>
          <li>
            De relatieve vochtigheid is in procentpunten (voorbeeld: 80% in de
            referentie, met 1% erbij wordt 81%).
          </li>
          <li>
            De hoeveelheid neerslag in de referentieperiode is de hoeveelheid
            die gemiddeld eens per jaar op een locatie voorkomt, zoals
            beschreven in STOWA 2019-19, Neerslagstatistiek en -reeksen voor het
            waterbeheer 2019, update volgt in 2024. Afgerond op hele getallen.
          </li>
          <li>
            Gebaseerd op een andere methodiek en modellen en met een
            onzekerheidsband vanwege de bandbreedte in kleinschalige processen
            rond buien (zie hoofdstuk Zomerbuien, hagel en onweer in het{" "}
            <a
              href="https://www.knmi.nl/kennis-en-datacentrum/achtergrond/knmi-23-klimaatscenario-s"
              target="_blank"
            >
              gebruikersrapport
            </a>
            ).
          </li>
        </ol>
      </div>
    </div>
  );
};

export default ClimateScenarioTablePdf;