import { PropsWithChildren, createElement } from "react"
import classNames from "classnames"

import { HeadingProps } from "./Heading.types"
import styles from './heading.module.css'

const Heading = ({
  level,
  stylingLevel = level,
  centered,
  children,
  ...headingProps
}: PropsWithChildren<HeadingProps>) => {
  const heading = createElement(
    level > 0 ? `h${level}` : 'div',
    {
      ...headingProps,
      className: classNames(styles.heading, headingProps.className, {
        [styles[`heading${stylingLevel}`]]: stylingLevel,
        [styles.centered]: centered
      }),
    },
    children
  )
  return heading
}

export default Heading